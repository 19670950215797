import Screen from './_Screen'
import lerp from 'lerp'

import { getAsset } from '../manager/preloader'
import { dispatch, getState } from '../manager/state'
import Player from '../objects/Player'

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this._center = this._width / 2
    this._signetSize = 25

    this._pos = [this._width / 2, this._height + this._signetSize / 2]

    this._logoX = -101.5
    this._signetX = 75
    this._signetY = -12.5
    this._clipX = this._logoX
    this._playerTwo = new Player(this._ctx, this._width, this._height, 0, 'hidden')

    this.name = 'menu'

    this.logo = getAsset('logo')
    this.signet = getAsset('signet')

    this._scale = 1
    this._clipWidth = 201

    this._animations = {
      fadein: (time) => {
        const target = this._height / 2
        this._pos[1] = lerp(this._pos[1], target, 0.1)

        if (Math.floor(this._pos[1]) === Math.floor(target)) {
          this._animation = this._animations.shrink
        }
      },
      shrink: () => {
        this._logoX = lerp(this._logoX, 25, 0.1)
        this._signetX = lerp(this._signetX, -12, 0.1)
        this._clipWidth = lerp(this._clipWidth, 24, 0.1)
        this._clipX = lerp(this._clipX, -12, 0.1)

        if (Math.ceil(this._logoX) === 25 &&
          Math.floor(this._signetX) === -12) {
          dispatch('playerAnimation', 'hopIn')
          this._playerTwo.setAnimation('hopInTwin')
          this._animation = this._animations.scale
        }
      },
      scale: () => {
        if (getState('playerAnimation') !== 'slideIn') {
          dispatch('playerAnimation', 'slideIn')
          this._playerTwo.setAnimation('slideInTwin')
        }

        this._signetY = lerp(this._signetY, -6, 0.1)
        this._scale = lerp(this._scale, 100, 0.1)

        if (Math.ceil(this._playerTwo._pos[0]) === Math.ceil(this._width / 2 + 40) && getState('playstate') !== 'playing') {
          dispatch('playstate', 'playing')
        }
      }
    }

    this._animation = () => {}
  }

  mount () {
    setTimeout(() => {
      this._animation = this._animations.fadein
    }, 1000)
  }

  unmount () {

  }

  _startGame () {
    dispatch('playstate', 'playing')
  }

  update (time, finger) {
    this._animation(time)
    this._playerTwo.update(time, finger)
  }

  draw () {
    this._ctx.beginPath()
    this._ctx.fillStyle = '#ff5511'
    this._ctx.fillRect(0, 0, this._width, this._height)

    this._ctx.save()
    this._ctx.translate(this._pos[0], this._pos[1])
    this._ctx.scale(this._scale, this._scale)

    this._ctx.beginPath()
    this._ctx.rect(this._clipX, -12.5, this._clipWidth, 25)
    this._ctx.clip()

    this._ctx.drawImage(this.logo, this._logoX, -12.5, 170, 24)

    this._ctx.drawImage(this.signet, this._signetX, this._signetY, 25, 24)
    this._ctx.restore()

    this._playerTwo.draw()
  }
}
