import Menuscreen from '../screens/Menu'
import Gamescreen from '../screens/Game'
import Gameoverscreen from '../screens/Gameover'
import Snow from '../screens/Snow'
import Leaderboard from '../screens/Leaderboard'
import Test from '../screens/Test'
import Intro from '../screens/Intro'
import Wishes from '../screens/Wishes'

let activeScreens = []

const screens = {}

export const initScreens = (_ctx, _controlCtx, _width, _height) => {
  screens.menu = new Menuscreen(_ctx, _controlCtx, _width, _height)
  screens.game = new Gamescreen(_ctx, _controlCtx, _width, _height)
  screens.gameover = new Gameoverscreen(_ctx, _controlCtx, _width, _height)
  screens.snow = new Snow(_ctx, _controlCtx, _width, _height)
  screens.leaderboard = new Leaderboard(_ctx, _controlCtx, _width, _height)
  screens.test = new Test(_ctx, _controlCtx, _width, _height)
  screens.intro = new Intro(_ctx, _controlCtx, _width, _height)
  screens.wishes = new Wishes(_ctx, _controlCtx, _width, _height)
}

export const getActiveScreens = () => {
  return activeScreens
}

export const addScreens = (...screenNames) => {
  screenNames.forEach(name => {
    const screen = screens[name]
    activeScreens.push(screen)
    screen.mount()
  })
}

export const removeScreens = (...screenNames) => {
  activeScreens = activeScreens.filter(s => {
    if (screenNames.indexOf(s.name) !== -1) {
      s.unmount()
      return false
    }
    return true
  })
}

export const removeAllScreensBut = (...screenNames) => {
  activeScreens = activeScreens.filter(s => {
    if (screenNames.indexOf(s.name) === -1) {
      s.unmount()
      return false
    }
    return true
  })
}

export const clearScreens = () => {
  activeScreens = activeScreens.filter(s => {
    s.unmount()
    return false
  })
}
