import Component from './_Component'

export default class extends Component {
  constructor (ctx, className, title, onClick) {
    super(ctx)

    this._size = [205, 55]
    this._onClick = onClick
    this._pos = [0, 0]

    this._element = document.createElement('button')
    this._element.className = `btn ${className}`
    this._element.style = `
      font-family: Barlow, Helvetica, Arial;
      font-size: 16px;
      font-weight: 800;
      font-style: italic;
      line-height: normal;
      letter-spacing: 1px;
      color: #ffffff;
      appearance: none;
      cursor: pointer;
      border: none;
      width: ${this._size[0]}px;
      height: ${this._size[1]}px;
      background: none;
      text-transform: uppercase;
      ${this._generatePosition(this._pos[0], 'x')}
      ${this._generatePosition(this._pos[1], 'y')}
      position: relative;
      outline: none;
      padding: 0;
      border-radius: ${this._size[1] / 2}px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    `

    this._appendTransforms()

    const btnBorder = document.createElement('div')
    btnBorder.style = `
      border-radius: ${this._size[1] / 2}px;
      background: none;
      background-image: linear-gradient(75deg,
        rgba(250, 79, 79, 0.2),
        rgba(255, 85, 17, 0.2) 50%,
        rgba(255, 137, 17, 0.2)
      );
      text-transform: uppercase;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      outline: none;
      transform-origin: center;
      animation: pulse 1s linear infinite;
    `

    const btnTitle = document.createElement('div')
    btnTitle.className = 'btn__title'
    btnTitle.innerText = title

    btnTitle.style = `
      width: ${this._size[0] - 16}px;
      height: ${this._size[1] - 8}px;
      border-radius: ${(this._size[1] - 8) / 2}px;
      background-image: linear-gradient(75deg, #fa4f4f, #ff5511 50%, #ff8911);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    `

    this._element.addEventListener('click', (e) => this._onClick())

    this._element.appendChild(btnBorder)
    this._element.appendChild(btnTitle)
  }
}
