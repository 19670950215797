import Asset from './_Asset'
import lerp from 'lerp'
import { getAsset } from '../manager/preloader'

export default class extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)

    this._scale = [0, 0]

    this.img = this._registerImg(28, 28, getAsset('cookie'))

    this._animations['scaleIn'] = (x, y) => {
      this._scale[0] = lerp(this._scale[0], 1, 0.5)
      this._scale[1] = lerp(this._scale[1], 1, 0.5)
    }

    this._animations['scaleOut'] = (x, y) => {
      this._scale[0] = lerp(this._scale[0], 0, 0.5)
      this._scale[1] = lerp(this._scale[1], 0, 0.5)

      if (Math.floor(this._scale[0]) === 0) {
        this._remove = -1
      }
    }

    this.setAnimation('scaleIn')

    this._remove = 0

    setTimeout(() => {
      this.setAnimation('scaleOut')
    }, 2000)
  }

  checkBounds () {
    return this._remove
  }

  _drawCookie (width, height) {
    this._ctx.save()
    this._ctx.translate(width - 66, height - 66)
    this._ctx.save()
    this._ctx.translate(56, 0)
    this._ctx.scale(-1, 1)
    this._ctx.drawImage(this.img, 0, 0, 56, 56)
    this._ctx.restore()

    this._ctx.restore()
  }

  draw () {
    const width = this._width - 20
    const height = 120

    this._ctx.save()
    this._ctx.translate(10, this._pos[1])

    this._ctx.save()
    this._ctx.translate(width / 2, height / 2)
    this._ctx.scale(...this._scale)

    this._ctx.save()
    this._ctx.translate(width / -2, height / -2)
    this._ctx.beginPath()
    this._ctx.arc(6, 6, 6, 0, Math.PI * 2)
    this._ctx.arc(this._width - 26, 6, 6, 0, Math.PI * 2)
    this._ctx.arc(this._width - 26, 114, 6, 0, Math.PI * 2)
    this._ctx.arc(6, 114, 6, 0, Math.PI * 2)
    this._ctx.moveTo(6, 0)
    this._ctx.lineTo(this._width - 28, 0)
    this._ctx.lineTo(this._width - 20, 6)
    this._ctx.lineTo(this._width - 20, 114)
    this._ctx.lineTo(this._width - 29, 120)
    this._ctx.lineTo(6, 120)
    this._ctx.lineTo(0, 111)
    this._ctx.lineTo(0, 6)
    this._ctx.clip()

    const gradient = this._ctx.createLinearGradient(0, 0, this._width - 20, 0)
    gradient.addColorStop(0, '#cccccc')
    gradient.addColorStop(0.1, '#d6d6d6')
    gradient.addColorStop(1, '#ebebeb')

    this._ctx.beginPath()
    this._ctx.fillStyle = '#cccccc'
    this._ctx.fillRect(0, 0, this._width - 20, 120)

    this._ctx.beginPath()
    this._ctx.fillStyle = gradient
    this._ctx.fillRect(0, 0, this._width - 20, 20)

    this._ctx.beginPath()
    this._ctx.strokeStyle = '#bdbdbd'
    this._ctx.lineWidth = 1
    this._ctx.lineCap = 'butt'
    this._ctx.moveTo(0, 21)
    this._ctx.lineTo(this._width - 20, 21)
    this._ctx.stroke()

    this._ctx.beginPath()
    this._ctx.fillStyle = 'black'
    this._ctx.textAlign = 'left'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 16px Barlow'
    this._ctx.fillText('Cookie warning', 10, 30)

    this._ctx.beginPath()
    this._ctx.fillStyle = 'black'
    this._ctx.textAlign = 'left'
    this._ctx.textBaseline = 'top'
    this._ctx.font = '16px Barlow'
    this._ctx.fillText('This message will', 10, 55)

    this._ctx.beginPath()
    this._ctx.fillStyle = 'black'
    this._ctx.textAlign = 'left'
    this._ctx.textBaseline = 'top'
    this._ctx.font = '16px Barlow'
    this._ctx.fillText('destruct itself in 3 seconds', 10, 75)

    this._drawCookie(width, height)

    this._ctx.restore()

    this._ctx.restore()

    this._ctx.restore()
  }
}
