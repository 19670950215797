import Gameobject from './_Gameobject'

import { vec2 } from 'gl-matrix'
import lerp from 'lerp'
import { hueCycle, degToRad, mapRange } from '../utils'

export default class extends Gameobject {
  constructor (_ctx, _width, _height, _initialX = 0, startAnimation = 'intro') {
    super(_ctx, _width, _height)

    this.type = 'player'
    this._startHeight = 200
    this._pos = vec2.set([], _initialX || _width - 100, 0)
    this._scale = [1, 1]
    this._lastPos = this._pos.slice(0)
    this._slopeAngle = 0
    this._lastPoints = []
    this._animation = null
    this._maxTailLength = 250
    this._size = [11, 29]
    this._halfWidth = this._size[0] / 2
    this._rotation = 45
    this._forceRotation = 0
    this._lastX = 0
    this._showTail = true
    this._prerendered = null

    this._animations = {
      hidden: () => {
        this._showTail = false
        this._pos[1] = this._size[1] * -1
      },
      hopIn: (x, y) => {
        this._forceRotation = 45
        this._pos[0] = this._width / 2 - 3
        this._pos[1] = this._height / 2 + 2
        this._scale = [0.4, 0.4]
      },
      hopInTwin: (x, y) => {
        this._forceRotation = 65
        this._pos[0] = this._width / 2 + 6
        this._pos[1] = this._height / 2 + 5
        this._scale = [0.4, 0.4]
      },
      slideIn: (x, y) => {
        this._showTail = false
        this._forceRotation = 45

        y = this._startHeight

        const target = this._width / 2 + 40

        this._pos[0] = lerp(this._pos[0], target, 0.05)
        this._pos[1] = lerp(this._pos[1], y, 0.05)
        this._scale[0] = lerp(this._scale[0], 1, 0.05)
        this._scale[1] = lerp(this._scale[1], 1, 0.05)

        if (Math.ceil(this._pos[0]) === Math.ceil(target)) {
          this._lastPoints = []
          this.setAnimation('default')
        }
      },
      slideInTwin: (x, y) => {
        this._showTail = false
        this._forceRotation = 45

        y = this._startHeight

        const target = this._width / 2 + 40

        this._pos[0] = lerp(this._pos[0], target, 0.05)
        this._pos[1] = lerp(this._pos[1], y, 0.05)
        this._scale[0] = lerp(this._scale[0], 1, 0.05)
        this._scale[1] = lerp(this._scale[1], 1, 0.05)
      },
      slopes: (x, y) => {
        this._forceRotation = 0
        this._showTail = true
        x = x + this._makeSlopes(50, 100)
        y = this._startHeight

        this._pos[0] = lerp(this._pos[0], x, 0.1)
        this._pos[1] = lerp(this._pos[1], y, 0.1)
      },
      intro: () => {
        const x = this._width / 2
        const y = this._startHeight
        this._pos[0] = lerp(this._pos[0], x, 0.1)
        this._pos[1] = lerp(this._pos[1], y, 0.06)

        if (this._pos[1] >= y - 60) {
          this.setAnimation('default')
        }
      },
      default: (x, y, speed) => {
        this._forceRotation = 0
        this._showTail = true
        this._scale = [1, 1]

        y = this._startHeight

        // const target = x > this._width / 2 ? this._width : 0

        this._pos[0] = lerp(this._pos[0], x, 0.1)
        this._pos[1] = lerp(this._pos[1], y, 0.1)

        this._lastControllerX = x
      },
      free: (x, y) => {
        this._pos[0] = x
        this._pos[1] = y
      }
    }

    this.setAnimation(startAnimation)

    this._prerender()
  }

  _prerender () {
    this._prerendered = document.createElement('canvas')
    const offctx = this._prerendered.getContext('2d')
    offctx.beginPath()
    offctx.fillStyle = '#ff5511'
    offctx.arc(this._halfWidth, this._halfWidth, this._halfWidth, 0, Math.PI * 2)
    offctx.rect(0, this._halfWidth, this._size[0], this._size[1] - this._size[0])
    offctx.arc(this._halfWidth, this._size[1] - this._size[0] + this._halfWidth, this._halfWidth, 0, Math.PI * 2)
    offctx.fill()
  }

  _makeSlopes (width = 50, speed = 100) {
    return (Math.sin(this._slopeAngle / speed) * (width * -1))
  }

  _getRotation (x) {
    if (this._forceRotation) {
      return this._forceRotation
    }

    const r = mapRange(this._pos[0], this._lastX - 1, this._lastX + 1, 45, -45)
    if (r > 45) {
      return 45
    }

    if (r < -45) {
      return -45
    }

    return r
  }

  update (time, _contr, direction) {
    const speed = time / 10

    this._lastPos = this._pos.slice(0)

    if (this._showTail) {
      this._calcTailPoints(time / 2)
    }

    this._animation(_contr[0], this._pos[1], speed)

    this._keepInBounds()

    this._slopeAngle += speed

    this._rotation = this._getRotation(_contr[0])

    this._lastX = this._pos[0]
  }

  _calcTailPoints (speed) {
    this._lastPoints.push(vec2.add([], this._pos, [this._halfWidth, 0]))

    if (this._lastPoints.length > this._maxTailLength + 50) {
      this._lastPoints = this._lastPoints.slice(this._lastPoints.length - this._maxTailLength)
    }

    this._lastPoints = this._lastPoints.map(p => {
      p[1] -= speed
      return p
    })
  }

  _keepInBounds () {
    if (this._pos[0] > this._width) {
      this._pos[0] = this._width
    }

    if (this._pos[0] < 0) {
      this._pos[0] = 0
    }
  }

  _rainbowTail () {
    const hueStart = 17.1
    let prev = this._lastPoints[0]

    this._lastPoints
      .slice(this._maxTailLength * -1)
      .forEach((p, idx) => {
        this._ctx.beginPath()
        this._ctx.strokeStyle = hueCycle(hueStart, idx / this._maxTailLength)
        this._ctx.lineWidth = 4
        this._ctx.lineCap = 'round'
        this._ctx.lineJoin = 'round'
        this._ctx.moveTo(prev[0], prev[1])
        this._ctx.lineTo(p[0], p[1])
        this._ctx.stroke()
        prev = p
      })
  }

  _tail () {
    this._ctx.beginPath()
    var gradient = this._ctx.createLinearGradient(0, this._pos[1], 0, 20)
    gradient.addColorStop(0, '#ff5511')
    gradient.addColorStop(1, '#f1f1f1')
    this._ctx.strokeStyle = gradient
    this._ctx.lineWidth = 4
    this._ctx.lineCap = 'round'
    this._ctx.lineJoin = 'round'
    this._lastPoints.forEach((p) => this._ctx.lineTo(p[0], p[1]))
    this._ctx.stroke()
  }

  draw () {
    if (this._showTail) {
      this._rainbowTail()
    }

    this._ctx.save()
    this._ctx.translate(this._pos[0] + this._halfWidth, this._pos[1])
    this._ctx.rotate(degToRad(this._rotation))

    this._ctx.save()
    this._ctx.translate(this._halfWidth * -1, 0)
    this._ctx.scale(this._scale[0], this._scale[1])
    this._ctx.drawImage(this._prerendered, 0, 0)
    this._ctx.restore()

    this._ctx.restore()
  }

  getBoundingBox () {
    const halfY = this._size[1] / 2

    return [
      [
        this._pos[0] - this._rotation / 3,
        this._pos[1] + halfY - Math.abs(this._rotation / 6)
      ],
      [
        this._size[0],
        halfY
      ]
    ]
  }

  drawBoundingBox () {
    const [ pos, size ] = this.getBoundingBox()

    this._ctx.beginPath()
    this._ctx.lineWidth = 1
    this._ctx.lineJoin = 'square'
    this._ctx.strokeStyle = '#f00'
    this._ctx.strokeRect(pos[0], pos[1], size[0], size[1])
  }
}
