import Asset from './_Asset'
import { getAsset } from '../manager/preloader'

export default class extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)

    this._size = [33, 36]

    this._pointAmt = 50

    this.type = 'item'

    this.img = this._registerImg(this._size[0], this._size[1], getAsset('snowy'))

    this.setAnimation('hover')
  }

  drawShadow () {
    this._dropShadow()
  }

  isHit (countHit, promoteToForeground) {
    if (this._hitByPlayer) {
      return
    }

    this._explode()

    this.setAnimation('hit')
    this._hitByPlayer = true

    promoteToForeground('sb')
    countHit(this._pointAmt, 'snowies', 'snowieCount')
  }
}
