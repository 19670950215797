import Screen from './_Screen'
import Player from '../objects/Player'
import { boxCollision, rand } from '../utils'
import lerp from 'lerp'

import TreeSingle from '../objects/TreeSingle'
import TreeXmas from '../objects/TreeXmas'
import TreeDouble from '../objects/TreeDouble'
import TreeDoubleFlip from '../objects/TreeDoubleFlip'
import Cookie from '../objects/Cookie'
import Present from '../objects/Present'
import Snowy from '../objects/Snowy'
import Warning from '../objects/Warning'
import Snowballs from '../objects/Snowballs'
import Candy from '../objects/Candy'
import Star from '../objects/Star'

import { dispatch, subscribe, getState } from '../manager/state'
import Lift from '../objects/Lift'
import { getAsset } from '../manager/preloader'

const ASSET_MAP = {
  t: TreeSingle,
  T: TreeDouble,
  TF: TreeDoubleFlip,
  x: TreeXmas,
  c: Cookie,
  p: Present,
  s: Snowy,
  w: Warning,
  sb: Snowballs,
  ca: Candy,
  st: Star
}

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'game'

    this.wave1 = getAsset('wave1')
    this.wave2 = getAsset('wave2')
    this.wave3 = getAsset('wave3')

    this._wave1Y = rand(this._height, this._height * 3)
    this._wave2Y = rand(this._height, this._height * 3)
    this._wave3Y = rand(this._height, this._height * 3)

    this._player = null
    this._assets = []
    this._updateFunctions = []
    this._drawFunctions = []
    this._lastFinger = []

    this._backgroundUpdateFunctions = []
    this._backgroundDrawFunctions = []
    this._foregroundObjects = []

    this._row = 0
    this._maxAssets = 400
    this._assetCount = 0
    this._pointScale = 1

    this._mapWorker = new Worker('../map-generator.js')
    this._mapWorker.addEventListener('message', (e) => {
      this._createMapObject(e.data)
    })

    this._mapWorker.postMessage({
      func: 'init',
      props: [this._height]
    })

    this._screenCenter = [this._width / 2, this._height / 2]

    this._resetObjects()

    subscribe('playstate', (type, prevType) => {
      switch (type) {
        case 'playing':
          this._start(prevType)
          break
      }
    })

    subscribe('playerAnimation', (value) => {
      this._player.setAnimation(value)
    })

    this._drawDistance = this._drawDistance.bind(this)
  }

  _initBackgroundWaves () {
    this._backgroundUpdateFunctions.push((time) => {
      this._wave1Y -= time / 4
      this._wave2Y -= time / 4
      this._wave3Y -= time / 4

      if (this._wave1Y + 252 < 0) {
        this._wave1Y = rand(this._height, this._height * 3)
      }

      if (this._wave2Y + 239 < 0) {
        this._wave2Y = rand(this._height, this._height * 3)
      }

      if (this._wave3Y + 246 < 0) {
        this._wave3Y = rand(this._height, this._height * 3)
      }
    })

    this._backgroundDrawFunctions.push(() => {
      this._ctx.drawImage(this.wave1, this._width - 275, this._wave1Y, 275, 252)
      this._ctx.drawImage(this.wave2, 0, this._wave2Y, 229, 239)
      this._ctx.drawImage(this.wave3, this._width / 2 - 309 / 2, this._wave2Y, 309, 246)
    })
  }

  _createMapObject (objectData) {
    const assets = objectData.map(data => {
      this._assetCount++
      return this._createAsset(data.type, data.pos)
    })

    this._assets.push(...assets)
  }

  _createAsset (type, pos) {
    return new ASSET_MAP[type](
      this._ctx,
      this._width,
      this._height,
      pos || [this._width / 2, rand(this._height / 2, this._height - 200)]
    )
  }

  _cleanObjects (objects, remove) {
    return objects.filter(a => {
      if (a.checkBounds() === -1) {
        remove()
        return false
      }

      return true
    })
  }

  _registerObject (obj) {
    this._updateFunctions.push(obj.update.bind(obj))
    this._drawFunctions.push(obj.draw.bind(obj))
  }

  _resetObjects () {
    dispatch('presents', 0)
    dispatch('cookies', 0)
    dispatch('snowies', 0)
    dispatch('candy', 0)
    dispatch('presentCount', 0)
    dispatch('cookieCount', 0)
    dispatch('snowieCount', 0)
    dispatch('candyCount', 0)
    dispatch('points', 0)
    dispatch('distance', 0)

    const time = (new Date()).getTime()
    dispatch('startTime', time)
    dispatch('endTime', time)

    this._updateFunctions = []
    this._drawFunctions = []
    this._backgroundUpdateFunctions = []
    this._backgroundDrawFunctions = []
    this._foregroundObjects = []
    this._assets = []
    this._row = 0

    this._player = new Player(this._ctx, this._width, this._height, 0, 'hidden')
    this._registerObject(this._player)
    this._lift = new Lift(this._ctx, this._width, this._height, [0, this._height * 2 + 30], true, true, 2)

    this._mapWorker.postMessage({
      func: 'reset',
      props: []
    })
  }

  _hitObstacle () {
    dispatch('endTime', (new Date()).getTime())
    dispatch('playstate', 'gameover')
  }

  _drawDistance () {
    this._ctx.save()
    this._ctx.translate(this._screenCenter[0], 20)
    this._ctx.fillStyle = 'black'
    this._ctx.textAlign = 'center'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 16px Barlow'
    this._ctx.fillText(`${getState('points')}`, 0, 0)
    this._ctx.restore()

    this._ctx.save()
    this._ctx.translate(this._width - 20, 20)
    this._ctx.fillStyle = 'black'
    this._ctx.textAlign = 'right'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 16px Barlow'
    this._ctx.fillText(`${Math.floor(getState('distance'))}m`, 0, 0)
    this._ctx.restore()
  }

  _start (prevType) {
    if (this._notPlaying(prevType)) {
      this._resetObjects()
    }

    this._assetCount = 0

    this._player.setAnimation('intro')
    this._drawFunctions.push(this._drawDistance)
    this._updateFunctions.push((time) => {
      dispatch('distance', getState('distance') + 0.5)

      if (this._assetCount < this._maxAssets) {
        this._mapWorker.postMessage({
          func: 'generateRow',
          props: [9, this._row, [this._width, this._height]]
        })

        this._row++
      }
    })

    this._registerObject(this._lift)

    this._initBackgroundWaves()
  }

  _notPlaying (state) {
    return ['gameover', 'leaderboard'].indexOf(state) !== -1
  }

  _countPoint (points) {
    dispatch('points', getState('points') + points)
    this._pointScale = 0
  }

  update (time, fingerPos, direction) {
    if (this._notPlaying(getState('playstate'))) {
      time = 0
      fingerPos = this._lastFinger
      return
    }

    this._pointScale = lerp(this._pointScale, 2, 0.1)

    this._updateFunctions.map(f => f(time, fingerPos, direction))
    this._assets.map(a => a.update(time, fingerPos, direction))

    if (!this._notPlaying(getState('playstate'))) {
      this._assets.forEach(a => {
        if (boxCollision(this._player, a)) {
          switch (a.type) {
            case 'obstacle':
              a.isHit()
              this._hitObstacle()
              break
            case 'item':
              a.isHit(
                (points, field, countField) => {
                  dispatch(field, getState(field) + points)
                  dispatch(countField, getState(countField) + 1)
                  this._countPoint(points)
                },
                (type) => {
                  this._foregroundObjects.push(this._createAsset(type))
                }
              )
              break
          }
        }
      })
    }

    const [ playerPos, playerSize ] = this._player.getBoundingBox()
    if (playerPos[0] <= 0 || playerPos[0] + playerSize[0] >= this._width) {
      this._hitObstacle()
    }

    this._backgroundUpdateFunctions.forEach(f => f(time))
    this._foregroundObjects.forEach(obj => obj.update())

    this._lastFinger = fingerPos
  }

  draw () {
    this._backgroundDrawFunctions.forEach(f => f())
    this._assets.forEach(a => a.drawShadow())
    this._assets.forEach(a => a.draw())
    this._drawFunctions.forEach(f => f())
    this._foregroundObjects.forEach(obj => obj.draw())
    this._assets = this._cleanObjects(this._assets, () => this._assetCount--)
    this._foregroundObjects = this._cleanObjects(this._foregroundObjects, () => {})
  }
}
