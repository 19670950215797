import Button from '../components/Button'
import Screen from './_Screen'
import { initAudio } from '../manager/audio'

import { dispatch } from '../manager/state'

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'menu'

    this._style = `
      .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .cta {
        margin-top: auto;
        margin-bottom: 10.19%;
      }
    `

    this._button = new Button(
      _controlCtx,
      'cta',
      'START RIDING',
      () => {
        initAudio()
        this._startGame()
      }
    )
  }

  mount () {
    this._appendStyle()
    this._button.mount()
  }

  unmount () {
    this._button.unmount()
    this._removeStyle()
  }

  _startGame () {
    dispatch('playstate', 'playing')
  }

  draw () {
    this._ctx.save()
    this._ctx.fillStyle = 'rgba(255, 255, 255, 0.3)'
    this._ctx.beginPath()
    this._ctx.fillRect(0, 0, this._width, this._height)
    this._ctx.restore()
  }
}
