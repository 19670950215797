import Asset from './_Asset'

import { getAsset } from '../manager/preloader'

export default class TreeSingle extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)
    this._size = [28, 44]

    this.type = 'obstacle'

    this.img = this._registerImg(this._size[0], this._size[1], getAsset('treesingle'))
    this.shadow = this._registerImg(49, 45, getAsset('shadow'))
  }

  drawShadow () {
    const [pos] = this.getBoundingBox()

    if (pos[1] > this._height) {
      return
    }

    this._ctx.save()
    this._ctx.globalAlpha = this._opacity
    this._ctx.translate(pos[0] + 6, pos[1] + 30)
    this._ctx.drawImage(this.shadow, 0, 0, 49, 45)
    this._ctx.restore()
  }
}
