export const rand = (min, max) => {
  return Math.random() * (max - min + 1) + min
}

export const degToRad = (deg) => deg * Math.PI / 180

export const percentage = (value, max) => (value * 100) / max

export const lerp = (min, max, t) => min * (1 - t) + max * t

export const mapRange = (num, inMin, inMax, outMin, outMax) => (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin

export const hueCycle = (hueStart, t, sat = 1, light = 0.533) => {
  const hue = (t + hueStart) % 1
  const hsl = [
    Math.floor(hue * 360),
    `${Math.floor(100 * sat)}%`,
    `${Math.floor(100 * light)}%`
  ].join(', ')

  return `hsl(${hsl})`
}

export const boxCollision = (player, object) => {
  const [objectPos, objectSize] = object.getBoundingBox()
  const [playerPos, playerSize] = player.getBoundingBox()

  return objectPos[0] < playerPos[0] + playerSize[0] &&
    objectPos[0] + objectSize[0] > playerPos[0] &&
    objectPos[1] < playerPos[1] + playerSize[1] &&
    objectPos[1] + objectSize[1] > playerPos[1]
}

export const circleCollision = (player, object) => {
  const [pos, size] = object.getBoundingBox()
  let x1 = player._pos[0]
  let y1 = player._pos[1]

  if (player._rotation < 0) {
    x1 += (player._rotation * -1) / 2
    y1 -= (player._rotation * -1) / 4
  } else {
    x1 += (player._rotation * -1) / 2
    y1 += (player._rotation * -1) / 4
  }

  return ((pos[0] - x1) * (pos[0] - x1)) + ((y1 - pos[1]) * (y1 - pos[1])) <= (((player._size[0] / 2) + (size[0] / 2)) * ((player._size[0] / 2) + (size[0] / 2)))
}
