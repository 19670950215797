import { vec2 } from 'gl-matrix'
import { rand, mapRange } from '../utils'
import lerp from 'lerp'

import Gameobject from './_Gameobject'
import Explosion from './Explosion'

export default class Asset extends Gameobject {
  constructor (_ctx, _width, _height, _pos = null) {
    super(_ctx, _width, _height)

    this.type = 'asset'
    this._size = [44, 44]
    this._opacity = 1
    this.shadow = null

    this._updateFunctions = []
    this._drawFunctions = []

    this._float = 0
    this._sin = 0
    this._shadowY = _pos[1]
    this._hitByPlayer = false
    this._rand = Math.random()
    this._pointAmt = 0
    this._explosion = 0

    this._pos = _pos || vec2.set([], rand(0, _width), rand(_height, _height + 100))
    this._initialPos = this._pos.slice(0)
    this._countPos = this._pos.slice(0)
    this._countOpacity = 0

    this._explosion = null

    this._animations = {
      default: (x, y) => {
        this._pos = vec2.add([], this._pos, [x, y])
        this._countPos = this._pos.slice(0)
      },
      hit: (x, y) => {
        this._pos = vec2.add([], this._pos, [x, y - 10])
        this._opacity = lerp(this._opacity, 0, 0.1)
        this._countPos = vec2.add([], this._countPos, [x, y])
        this._countOpacity = lerp(this._opacity, this._pointAmt ? 1 : 0, 0.05)
      },
      hover: (x, y) => {
        this._sin = Math.sin(this._float) * -1
        this._pos = vec2.add([], this._pos, [x, y])
        this._countPos = this._pos.slice(0)
        this._shadowY += y
        this._float += 0.05
        this._scale = [
          mapRange(Math.abs(Math.sin(this._float)), 0, 1, 1.2, 1),
          mapRange(Math.abs(Math.sin(this._float)), 0, 1, 1, 1.2)
        ]
      }
    }

    this.setAnimation('default')
  }

  _registerImg (w, h, img) {
    img.width = w
    img.height = h
    return img
  }

  _explode () {
    this._explosion = new Explosion(this._ctx, this._width, this._height, this._pos)
    this._updateFunctions.push(() => this._explosion.update(this._countPos))
    this._drawFunctions.push(() => this._explosion.draw())
    this._explosion.ignite()
  }

  update (time, controller) {
    this._animation(0, -time / 4)
    this._updateFunctions.forEach(f => f())
  }

  isHit () {
    if (this._hitByPlayer) {
      return
    }

    this._explode()

    this._hitByPlayer = true
    // this.setAnimation('hit')
  }

  draw () {
    const [pos, size] = this.getBoundingBox()

    if (pos[1] > this._height) {
      return
    }

    this._ctx.save()
    this._ctx.globalAlpha = this._opacity
    this._ctx.translate(pos[0], pos[1] + Math.abs(this._sin) * -12)

    this._ctx.save()
    this._ctx.translate(size[0] / 2, size[1] / 2)
    this._ctx.scale(this._scale[0], this._scale[1])
    this._ctx.drawImage(this.img, size[0] / -2, size[1] / -2, size[0], size[1])
    this._ctx.restore()

    this._ctx.restore()

    this._ctx.save()
    this._ctx.translate(this._countPos[0] + this._size[0] / 2, this._countPos[1] - this._size[1] / 2)
    this._ctx.globalAlpha = this._countOpacity
    this._ctx.fillStyle = '#ff5511'
    this._ctx.textAlign = 'left'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 16px Barlow'
    this._ctx.fillText(`+${this._pointAmt}`, 0, 0)
    this._ctx.restore()
    this._drawFunctions.forEach(f => f())
  }

  drawShadow () {}

  drawForeground () {}

  _dropShadow () {
    const [pos, size] = this.getBoundingBox()

    if (pos[1] > this._height) {
      return
    }

    const absSin = Math.abs(this._sin)

    this._ctx.save()
    this._ctx.translate(pos[0], this._shadowY)
    this._ctx.save()
    this._ctx.beginPath()
    this._ctx.fillStyle = 'rgba(0,0,0,0.1)'
    this._ctx.globalAlpha = this._opacity
    this._ctx.translate(size[0] / 2, size[1])
    this._ctx.scale(absSin, absSin - 0.5)
    this._ctx.ellipse(0, 0, 15, 5, 0, 0, Math.PI * 2)
    this._ctx.fill()
    this._ctx.restore()
    this._ctx.restore()
  }
}
