import _Component from './_Component'

export default class extends _Component {
  constructor (ctx, className, items, render) {
    super(ctx)

    this._renderChild = render

    this._element = document.createElement('ul')
    this._element.className = className

    this._renderItems(items)

    this._element.addEventListener('touchmove', e => e.stopPropagation(), { passive: false })
  }

  update (items) {
    this._renderItems(items)
  }

  _renderItems (items) {
    const fragment = document.createDocumentFragment()
    items.forEach((item, idx) => {
      fragment.appendChild(this._renderChild(item, idx, items.length - 1 === idx))
    })

    this._element.textContent = ''
    this._element.appendChild(fragment)
  }
}
