import Asset from './_Asset'

import { getAsset } from '../manager/preloader'
import { rand } from '../utils'

export default class extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)

    this._size = [20, 20]

    this.type = 'item'

    this._pointAmt = 1

    const imgs = [
      getAsset('candyred'),
      getAsset('candyblue'),
      getAsset('candygreen'),
      getAsset('candyorange')
    ]

    const img = imgs[Math.floor(rand(0, 3))]

    this.img = this._registerImg(this._size[0], this._size[1], img)

    this.setAnimation('default')
  }

  drawShadow () {
    this._dropShadow()
  }

  isHit (countHit) {
    if (this._hitByPlayer) {
      return
    }

    this._explode()

    this.setAnimation('hit')
    this._hitByPlayer = true

    countHit(this._pointAmt, 'candy', 'candyCount')
  }
}
