import { init } from './src/manager/game'
import stats from './src/stats'

if ('ontouchstart' in window) {
  // Avoid iOS drag events
  document.addEventListener('touchmove', e => e.preventDefault(), { passive: false })
}

document.addEventListener('DOMContentLoaded', (e) => {
  const width = window.innerWidth
  const height = window.innerHeight

  let canvas = document.querySelector('#paper')
  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')

  const controls = document.querySelector('#controls')

  if (window.location.hash.indexOf('stats') !== -1) {
    document.body.removeChild(document.querySelector('.loader'))
    stats()
    return
  }

  init(ctx, controls, width, height)
    .then(() => {
      document.body.removeChild(document.querySelector('.loader'))
    })
})

console.log('Hey')
