import Screen from './_Screen'
import Button from '../components/Button'
import Img from '../components/Img'
import Link from '../components/Link'
import Input from '../components/Input'
import lerp from 'lerp'

import gameover1 from '../img/gameover.png'
import gameover2 from '../img/gameover@2x.png'
import gameover3 from '../img/gameover@3x.png'

import Present from '../objects/Present'

import { dispatch, getState } from '../manager/state'
import Cookie from '../objects/Cookie'
import Snowy from '../objects/Snowy'
import Candy from '../objects/Candy'

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'gameover'
    this.userName = ''

    this._itemsY = 129 + 68 + 50

    this._present = new Present(_ctx, _width, _height, [_width / 2 - 120, this._itemsY + 8])
    this._cookie = new Cookie(_ctx, _width, _height, [_width / 2 - 40, this._itemsY + 8])
    this._snowy = new Snowy(_ctx, _width, _height, [_width / 2 + 120, this._itemsY])
    this._candy = new Candy(_ctx, _width, _height, [_width / 2 + 40, this._itemsY + 16])
    this._candy.setAnimation('hover')

    this._totalPoints = 0

    this._style = `
      .gameover {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .over {
        margin-top: 10.19%;
        margin-bottom: 196px;
      }

      .cta {
        margin-top: auto;
        margin-bottom: 10.19%;
      }

      .input {
        margin-top: auto;
        margin-bottom: 16px;
      }
    `

    this._button = new Button(
      _controlCtx,
      'cta',
      'RIDE AGAIN',
      () => this._startGame()
    )

    this._img = new Img(
      _controlCtx,
      'over',
      gameover1,
      `${gameover1}, ${gameover2} 2x, ${gameover3} 3x`
    )

    this._link = new Link(
      _controlCtx,
      'Highscores',
      () => dispatch('playstate', 'leaderboard')
    )

    this._input = new Input(
      _controlCtx,
      'Your Name',
      (name) => (this.userName = name),
      () => this._sendHighscore()
    )
  }

  mount () {
    this._appendStyle()

    this._img.mount()
    this._input.mount()
    this._link.mount()
    this._button.mount()

    this._totalPoints = 0
    this._targetOpacity = 1
  }

  unmount () {
    this._input.unmount()
    this._button.unmount()
    this._img.unmount()
    this._link.unmount()
    this._removeStyle()
  }

  _startGame () {
    dispatch('playstate', 'playing')
  }

  _sendHighscore () {
    const name = this.userName
    console.log((new Date(getState('endTime'))) - (new Date(getState('startTime'))))

    fetch('https://master-7rqtwti-h6iieht6zrdke.eu-2.platformsh.site/api/highscores', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name,
        presents: parseInt(getState('presents')),
        cookies: parseInt(getState('cookies')),
        snowmen: parseInt(getState('snowies')),
        candies: parseInt(getState('candy')),
        distance: parseInt(getState('distance')),
        time: parseInt((new Date(getState('endTime'))) - (new Date(getState('startTime'))))
      })
    })
      .then((res) => res.json())
      .then(data => {
        dispatch('playstate', 'leaderboard')
        dispatch('userId', data.id)
      })
  }

  update () {
    this._present.update(0)
    this._cookie.update(0)
    this._snowy.update(0)
    this._candy.update(0)
    this._totalPoints = lerp(this._totalPoints, getState('points'), 0.04)
  }

  _itemPoints (pos, points) {
    this._ctx.save()
    this._ctx.translate(pos[0], pos[1])
    this._ctx.fillStyle = '#646464'
    this._ctx.textAlign = 'center'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 16px Barlow'
    this._ctx.fillText(points, 0, 0)
    this._ctx.restore()
  }

  draw () {
    this._ctx.save()
    this._ctx.fillStyle = 'rgba(255, 255, 255, 1)'
    this._ctx.beginPath()
    this._ctx.fillRect(0, 0, this._width, this._height)
    this._ctx.restore()

    this._present.drawShadow()
    this._present.draw()
    this._itemPoints([this._width / 2 - 122, this._itemsY + 50], getState('presentCount'))

    this._cookie.drawShadow()
    this._cookie.draw()
    this._itemPoints([this._width / 2 - 42, this._itemsY + 50], getState('cookieCount'))

    this._candy.drawShadow()
    this._candy.draw()
    this._itemPoints([this._width / 2 + 38, this._itemsY + 50], getState('candyCount'))

    this._snowy.drawShadow()
    this._snowy.draw()
    this._itemPoints([this._width / 2 + 118, this._itemsY + 50], getState('snowieCount'))

    this._ctx.save()
    this._ctx.translate(this._width / 2 - 102, this._itemsY + 94)
    this._ctx.fillStyle = '#646464'
    this._ctx.textAlign = 'left'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 16px Barlow'
    this._ctx.fillText('Your Score', 0, 0)
    this._ctx.restore()

    this._ctx.save()
    this._ctx.translate(this._width / 2 + 102, this._itemsY + 90)
    this._ctx.fillStyle = 'black'
    this._ctx.textAlign = 'right'
    this._ctx.textBaseline = 'top'
    this._ctx.font = 'bold italic 20px Barlow'
    this._ctx.fillText(Math.ceil(this._totalPoints), 0, 0)
    this._ctx.restore()
  }
}
