import Asset from './_Asset'
import { getAsset } from '../manager/preloader'
import lerp from 'lerp'
import { rand } from '../utils'

export default class Wagon extends Asset {
  constructor (_ctx, _width, _height, _pos, startTime = 0) {
    super(_ctx, _width, _height, _pos)
    this._size = [20, 26]

    this._sin = 0
    this._playhead = startTime
    this._rand = rand(0.5, 1.1)

    this.type = 'obstacle'

    this.img = this._registerImg(this._size[0], this._size[1], getAsset('wagon'))
  }

  update (time) {
    this._sin += time / 400
    this._playhead += time

    const t = this._playhead / 20000

    this._pos[0] = lerp(this._initialPos[0], this._width, t)
    this._pos[1] = lerp(this._initialPos[1], -53, t)

    if (this._pos[0] - this._size[0] > this._width) {
      this._pos = this._initialPos.slice(0)
      this._playhead = 0
    }
  }

  drawShadow () {
    this._ctx.save()
    this._ctx.globalAlpha = this._opacity
    this._ctx.translate(this._pos[0], this._pos[1] + 60)
    this._ctx.beginPath()
    this._ctx.fillStyle = 'rgba(0,0,0,0.1)'
    this._ctx.ellipse(0, 0, 10, 5, 0, 0, Math.PI * 2)
    this._ctx.fill()
    this._ctx.restore()
  }

  draw () {
    this._ctx.save()
    this._ctx.globalAlpha = this._opacity
    this._ctx.translate(this._pos[0], this._pos[1])
    this._ctx.rotate(Math.sin(this._sin * this._rand) * 5 * Math.PI / 180)
    this._ctx.drawImage(this.img, -10, 0, this._size[0], this._size[1])
    this._ctx.restore()
  }
}
