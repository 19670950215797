import Asset from './_Asset'
import Wagon from './Wagon'
import { rand } from '../utils'

export default class Lift extends Asset {
  constructor (_ctx, _width, _height, _pos, _move = false, _shadows = false, count = 0) {
    super(_ctx, _width, _height, _pos)

    this._move = _move
    this._size = [1, 1]

    this._wagons = []
    const wagonCount = count || Math.ceil(this._width / 84) + 1

    for (let i = 0; i < wagonCount; i++) {
      this._wagons.push(
        new Wagon(
          this._ctx,
          this._width,
          this._height,
          [-11, 1],
          i / wagonCount * 20000
        )
      )
    }

    this._drawFuncs = [
      () => this._wagons.forEach(w => w.draw())
    ]

    if (_shadows) {
      this._drawFuncs = [() => this._wagons.forEach(w => w.drawShadow()), ...this._drawFuncs]
    }
  }

  update (time) {
    if (this._move) {
      this._pos[1] -= time / 4

      if (this._pos[1] + 26 < 0) {
        this._pos[1] = rand(this._height, this._height * 8)
      }
    }

    this._wagons.forEach(w => w.update(time))
  }

  draw () {
    this._ctx.save()
    this._ctx.beginPath()
    this._ctx.strokeStyle = '#dcdcdc'
    this._ctx.lineWidth = 2
    this._ctx.translate(this._pos[0], this._pos[1])
    this._ctx.moveTo(0, 0)
    this._ctx.lineTo(this._width, -53)
    this._ctx.stroke()
    this._drawFuncs.forEach(f => f())
    this._ctx.restore()
  }
}
