import Asset from './_Asset'
import lerp from 'lerp'

export default class extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)

    this._circleScales = [8, 12, 2]
    this._circlePos = [
      [0, 9],
      [0, 25],
      [0, 9]
    ]

    this._circleOpacities = [0, 0, 0]

    this._animations['scaleIn'] = (x, y) => {
      this._circleScales[0] = lerp(this._circleScales[0], 150, 0.5)

      this._circlePos[0][0] = lerp(this._circlePos[0][0], 80, 0.5)
      this._circlePos[0][1] = lerp(this._circlePos[0][1], -200, 0.5)

      this._circleOpacities[0] = lerp(this._circleOpacities[0], 0.98, 0.5)

      if (Math.ceil(this._circleScales[0]) > 90) {
        // Big Circle
        this._circleScales[1] = lerp(this._circleScales[1], 200, 0.5)

        this._circlePos[1][0] = lerp(this._circlePos[1][0], 0, 0.5)
        this._circlePos[1][1] = lerp(this._circlePos[1][1], 300, 0.5)

        this._circleOpacities[1] = lerp(this._circleOpacities[1], 0.98, 0.5)
      }

      if (Math.ceil(this._circleScales[1]) > 150) {
        // Nose
        this._circleScales[2] = lerp(this._circleScales[2], 100, 0.3)

        this._circlePos[2][0] = lerp(this._circlePos[2][0], 0, 0.3)
        this._circlePos[2][1] = lerp(this._circlePos[2][1], 0, 0.3)

        this._circleOpacities[2] = lerp(this._circleOpacities[2], 0.98, 0.3)
      }
    }

    this._animations['scaleOut'] = (x, y) => {
      this._circleOpacities[0] = lerp(this._circleOpacities[0], 0, 0.1)
      this._circlePos[0][1] = lerp(this._circlePos[0][1], this._height, 0.05)
      this._circleOpacities[1] = lerp(this._circleOpacities[1], 0, 0.1)
      this._circlePos[1][1] = lerp(this._circlePos[1][1], this._height, 0.05)
      this._circleOpacities[2] = lerp(this._circleOpacities[2], 0, 0.1)
      this._circlePos[2][1] = lerp(this._circlePos[2][1], this._height, 0.05)

      if (Math.floor(this._scale[0]) === 0) {
        this._remove = -1
      }
    }

    this.setAnimation('scaleIn')

    this._remove = 0

    setTimeout(() => {
      this.setAnimation('scaleOut')
    }, 2000)
  }

  checkBounds () {
    return this._remove
  }

  _drawCircle (radius = 0, pos, color, opacity) {
    this._ctx.save()
    this._ctx.globalAlpha = opacity
    this._ctx.translate(pos[0], pos[1])
    this._ctx.beginPath()
    this._ctx.fillStyle = color
    this._ctx.arc(0, 0, radius, 0, Math.PI * 2)
    this._ctx.fill()
    this._ctx.restore()
  }

  draw () {
    this._ctx.save()
    this._ctx.translate(this._pos[0], this._pos[1])
    this._drawCircle(this._circleScales[0], this._circlePos[0], '#f1f1f1', this._circleOpacities[0])
    this._drawCircle(this._circleScales[1], this._circlePos[1], '#f1f1f1', this._circleOpacities[1])
    this._drawCircle(this._circleScales[2], this._circlePos[2], '#ff5511', this._circleOpacities[2])
    this._ctx.restore()
  }
}
