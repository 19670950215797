import Screen from './_Screen'
import { rand } from '../utils'

// idea: https://codepen.io/pimskie/pen/jEVPNx

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'snow'

    this._numFlakes = 100
    this._flakes = []

    this.init()
  }

  mount () {

  }

  unmount () {

  }

  update () {
  }

  draw () {
    let i = this._flakes.length

    while (i--) {
      let flakeA = this._flakes[i]
      flakeA.update()

      flakeA.draw(this._ctx)

      if (flakeA.y >= this._height) {
        flakeA.y = -flakeA.weight
      }
    }
  }

  init () {
    let i = this._numFlakes

    while (i--) {
      const x = rand(0, this._width)
      const y = rand(0, this._height)

      this._flakes.push(new Flake(x, y))
    }
  }
}

export class Flake {
  constructor (x, y, white = false, alpha = 0) {
    const maxWeight = 5
    const maxSpeed = 3

    this._color = white ? 255 : 238

    this.x = x
    this.y = y

    this.r = 0.3
    this.a = 0.2
    this.aStep = 0.01

    this.weight = rand(1, 2)
    this.alpha = alpha || (this.weight / maxWeight)
    this.speed = (this.weight / maxWeight) * maxSpeed
  }

  update () {
    this.x += Math.cos(this.a) * this.r
    this.a += this.aStep

    this.y += this.speed
  }

  draw (ctx) {
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.weight, 0, 2 * Math.PI, false)
    ctx.fillStyle = `rgba(${this._color}, ${this._color}, ${this._color}, ${this.alpha})`
    ctx.fill()
  }
}
