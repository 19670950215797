import Asset from './_Asset'

import { getAsset } from '../manager/preloader'

export default class extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)

    this.type = 'item'

    this._pointAmt = 10

    this._size = [28, 28]

    this.img = this._registerImg(this._size[0], this._size[1], getAsset('present'))

    this.setAnimation('hover')
  }

  drawShadow () {
    this._dropShadow()
  }

  isHit (countHit) {
    if (this._hitByPlayer) {
      return
    }

    this._explode()
    this._hitByPlayer = true
    this.setAnimation('hit')

    countHit(this._pointAmt, 'presents', 'presentCount')
  }
}
