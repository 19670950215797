import Component from './_Component'

export default class extends Component {
  constructor (ctx, className, src, srcset) {
    super(ctx)

    this._element = document.createElement('img')
    this._element.className = className
    this._element.src = src
    this._element.srcset = srcset
  }

  mount () {
    this._ctx.appendChild(this._element)
  }

  unmount () {
    this._ctx.removeChild(this._element)
  }

  update () {

  }
}
