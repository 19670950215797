import cookie1x from '../img/cookie.png'
import present1x from '../img/present.png'
import treedouble1x from '../img/o-tree-double.png'
import treedoubleflip1x from '../img/o-tree-double-flip.png'
import treesingle1x from '../img/o-tree-single.png'
import treexmas1x from '../img/o-tree-xmas.png'
import gameover1x from '../img/gameover.png'
import shadow1x from '../img/shadow.png'
import snowy1x from '../img/snowy.png'
import logo1x from '../img/logo-title.png'
import signet1x from '../img/logo-signet.png'
import front1x from '../img/front.png'
import back1x from '../img/back.png'
import middle1x from '../img/middle.png'
import wagon1x from '../img/wagon.png'
import wave11x from '../img/wave1.png'
import wave21x from '../img/wave2.png'
import wave31x from '../img/wave3.png'
import candyred1x from '../img/candy-red.png'
import candyblue1x from '../img/candy-blue.png'
import candygreen1x from '../img/candy-green.png'
import candyorange1x from '../img/candy-orange.png'
import star1x from '../img/star.png'

import cookie2x from '../img/cookie@2x.png'
import present2x from '../img/present@2x.png'
import treedouble2x from '../img/o-tree-double@2x.png'
import treedoubleflip2x from '../img/o-tree-double-flip@2x.png'
import treesingle2x from '../img/o-tree-single@2x.png'
import treexmas2x from '../img/o-tree-xmas@2x.png'
import gameover2x from '../img/gameover@2x.png'
import shadow2x from '../img/shadow@2x.png'
import snowy2x from '../img/snowy@2x.png'
import front2x from '../img/front@2x.png'
import back2x from '../img/back@2x.png'
import middle2x from '../img/middle@2x.png'
import wagon2x from '../img/wagon@2x.png'
import signet2x from '../img/logo-signet@2x.png'
import logo2x from '../img/logo-title@2x.png'
import wave12x from '../img/wave1@2x.png'
import wave22x from '../img/wave2@2x.png'
import wave32x from '../img/wave3@2x.png'
import candyred2x from '../img/candy-red@2x.png'
import candyblue2x from '../img/candy-blue@2x.png'
import candygreen2x from '../img/candy-green@2x.png'
import candyorange2x from '../img/candy-orange@2x.png'
import star2x from '../img/star@2x.png'

import cookie3x from '../img/cookie@3x.png'
import present3x from '../img/present@3x.png'
import treedouble3x from '../img/o-tree-double@3x.png'
import treedoubleflip3x from '../img/o-tree-double-flip@3x.png'
import treesingle3x from '../img/o-tree-single@3x.png'
import treexmas3x from '../img/o-tree-xmas@3x.png'
import gameover3x from '../img/gameover@3x.png'
import shadow3x from '../img/shadow@3x.png'
import snowy3x from '../img/snowy@3x.png'
import front3x from '../img/front@3x.png'
import back3x from '../img/back@3x.png'
import middle3x from '../img/middle@3x.png'
import wagon3x from '../img/wagon@3x.png'
import signet3x from '../img/logo-signet@3x.png'
import logo3x from '../img/logo-title@3x.png'
import wave13x from '../img/wave1@3x.png'
import wave23x from '../img/wave2@3x.png'
import wave33x from '../img/wave3@3x.png'
import candyred3x from '../img/candy-red@3x.png'
import candyblue3x from '../img/candy-blue@3x.png'
import candygreen3x from '../img/candy-green@3x.png'
import candyorange3x from '../img/candy-orange@3x.png'
import star3x from '../img/star@3x.png'

const assets1x = {
  cookie1x,
  present1x,
  treedouble1x,
  treedoubleflip1x,
  treesingle1x,
  treexmas1x,
  gameover1x,
  shadow1x,
  snowy1x,
  logo1x,
  signet1x,
  front1x,
  middle1x,
  back1x,
  wagon1x,
  wave11x,
  wave21x,
  wave31x,
  candyred1x,
  candyblue1x,
  candygreen1x,
  candyorange1x,
  star1x
}

const assets2x = {
  cookie2x,
  present2x,
  treedouble2x,
  treedoubleflip2x,
  treesingle2x,
  treexmas2x,
  gameover2x,
  shadow2x,
  snowy2x,
  logo2x,
  signet2x,
  front2x,
  middle2x,
  back2x,
  wagon2x,
  wave12x,
  wave22x,
  wave32x,
  candyred2x,
  candyblue2x,
  candygreen2x,
  candyorange2x,
  star2x
}

const assets3x = {
  cookie3x,
  present3x,
  treedouble3x,
  treedoubleflip3x,
  treesingle3x,
  treexmas3x,
  gameover3x,
  shadow3x,
  snowy3x,
  logo3x,
  signet3x,
  front3x,
  middle3x,
  back3x,
  wagon3x,
  wave13x,
  wave23x,
  wave33x,
  candyred3x,
  candyblue3x,
  candygreen3x,
  candyorange3x,
  star3x
}

const assets = [assets1x, assets2x, assets3x][Math.ceil(window.devicePixelRatio) - 1]

const objects = {}

export const getAsset = (name) => objects[name] || null

export const preload = () => {
  return Promise.all(loadAssets())
}

const loadAssets = () => {
  return Object.keys(assets).map(key => {
    return new Promise(resolve => {
      const img = new Image(28, 28)
      img.src = assets[key]
      img.onload = () => resolve()
      objects[key.substr(0, key.length - 2)] = img
    })
  })
}
