import Screen from './_Screen'
import Button from '../components/Button'
import Text from '../components/Text'
import List from '../components/List'

import medal1 from '../img/medal1.png'
import medal2 from '../img/medal2.png'
import medal3 from '../img/medal3.png'

import medal12x from '../img/medal1@2x.png'
import medal22x from '../img/medal2@2x.png'
import medal32x from '../img/medal3@2x.png'

import medal13x from '../img/medal1@3x.png'
import medal23x from '../img/medal2@3x.png'
import medal33x from '../img/medal3@3x.png'

import { dispatch } from '../manager/state'

const medals = [
  medal1,
  medal2,
  medal3
]

const medals2x = [
  medal12x,
  medal22x,
  medal32x
]

const medals3x = [
  medal13x,
  medal23x,
  medal33x
]

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'leaderboard'

    this._style = `
      .leaderboard {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .title {
        font-size: 20px;
        font-weight: 800;
        font-style: italic;
        letter-spacing: 1.3px;
        color: #393939;
        margin-top: 10.19%;
        margin-bottom: 42px;
      }

      .list {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 42px;
      }

      .cta {
        margin-top: auto;
        margin-bottom: 10.19%;
      }
    `

    this._button = new Button(
      _controlCtx,
      'cta',
      'RIDE AGAIN',
      () => this._startGame()
    )

    this._text = new Text(
      _controlCtx,
      'title',
      'HIGHSCORES'
    )

    const items = []

    const gradient = [
      'linear-gradient(225deg, #fae153, #d29b24)',
      'linear-gradient(225deg, #cbcdcf, #8b8d91)',
      'linear-gradient(228deg, #d8a590, #ae602a)'
    ]

    this._list = new List(
      _controlCtx,
      'list',
      items,
      (item, idx, last) => {
        const tmp = document.createElement('li')
        tmp.style = `
          ${last ? '' : 'margin-bottom: 16px;'}
          display: flex;
          align-items: center;
          justify-content: space-between;
        `

        const number = `
          width: 28px;
          height: 28px;
          ${idx < 3 ? `background-image: ${gradient[idx]}; color: white;` : 'color: black;'}
          border-radius: 50%;
          flex-shrink: 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          margin-right: 16px;
          color: #949494;
        `

        const medalStyle = `
          margin-left: 4px;
          margin-right: 24px;
        `

        const name = `
          margin-right: 16px;
          color: #393939;
        `

        const points = `
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.5px;
          color: #393939;
        `

        const line = `
          display: flex;
          align-items: center;
        `

        let numberElement = `<div style="${number}">${idx + 1}.</div>`
        const medal = (src, srcset) => `<img style="${medalStyle} "src="medal" src="${src}" srcset="${srcset}">`

        if (idx < 3) {
          numberElement = medal(medals[idx], `${medals[idx]}, ${medals2x[idx]} 2x, ${medals3x[idx]}, 3x`)
        }

        tmp.innerHTML = `
          <div style="${line}">
            ${numberElement}
            <div style="${name}">${item.name}</div>
          </div>
          <div style="${points}">
            ${item.total_points}
          </div>`
        return tmp
      }
    )
  }

  mount () {
    this._appendStyle()
    this._text.mount()
    this._list.mount()
    this._button.mount()

    this.fetch()
      .then(data => {
        this._list.update(data)
      })
  }

  fetch () {
    return fetch('https://master-7rqtwti-h6iieht6zrdke.eu-2.platformsh.site/api/highscores')
      .then(res => res.json())
  }

  unmount () {
    this._removeStyle()
    this._button.unmount()
    this._text.unmount()
    this._list.unmount()
  }

  _startGame () {
    dispatch('playstate', 'playing')
  }

  draw () {
    this._ctx.save()
    this._ctx.fillStyle = 'rgba(255, 255, 255, 1)'
    this._ctx.beginPath()
    this._ctx.fillRect(0, 0, this._width, this._height)
    this._ctx.restore()
  }
}
