export default class {
  constructor (ctx, className, title) {
    this._ctx = ctx

    this._element = document.createElement('div')
    this._element.className = `txt ${className}`
    this._element.innerText = title
  }

  mount () {
    this._ctx.appendChild(this._element)
  }

  unmount () {
    this._ctx.removeChild(this._element)
  }

  update () {

  }
}
