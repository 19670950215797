import Asset from './_Asset'

import { getAsset } from '../manager/preloader'

export default class extends Asset {
  constructor (_ctx, _width, _height, _pos) {
    super(_ctx, _width, _height, _pos)

    this._size = [20, 20]

    this.type = 'item'

    this.img = this._registerImg(this._size[0], this._size[1], getAsset('star'))

    this.setAnimation('hover')
  }

  drawShadow () {
    this._dropShadow()
  }
}
