import List from './components/List'
import medal1 from './img/medal1.png'
import medal2 from './img/medal2.png'
import medal3 from './img/medal3.png'

import medal12x from './img/medal1@2x.png'
import medal22x from './img/medal2@2x.png'
import medal32x from './img/medal3@2x.png'

import medal13x from './img/medal1@3x.png'
import medal23x from './img/medal2@3x.png'
import medal33x from './img/medal3@3x.png'

const medals = [
  medal1,
  medal2,
  medal3
]

const medals2x = [
  medal12x,
  medal22x,
  medal32x
]

const medals3x = [
  medal13x,
  medal23x,
  medal33x
]

let items = []
let list = null

export default () => {
  document.body.innerHTML = ''
  document.body.style.background = 'white'

  const style = document.createElement('style')
  style.innerHTML = `
    body {
      padding: 20px;
      overflow: auto;
    }

    .list {
      max-width: 768px;
      margin: 0 auto;
    }

    .points {
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.5px;
      color: #393939;
      display: flex;
      align-items: center;
      font-family: monospace;
      font-size: 18px;
    }

    .points div {
      margin-right: 10px;
      min-width: 6ch;
      text-align: right;
    }
  `

  document.body.appendChild(style)

  const gradient = [
    'linear-gradient(225deg, #fae153, #d29b24)',
    'linear-gradient(225deg, #cbcdcf, #8b8d91)',
    'linear-gradient(228deg, #d8a590, #ae602a)'
  ]

  list = new List(
    document.body,
    'list',
    items,
    (item, idx, last) => {
      const tmp = document.createElement('li')
      tmp.style = `
        ${last ? '' : 'margin-bottom: 16px;'}
        display: flex;
        align-items: center;
        justify-content: space-between;
      `

      const number = `
        width: 28px;
        height: 28px;
        ${idx < 3 ? `background-image: ${gradient[idx]}; color: white;` : 'color: black;'}
        border-radius: 50%;
        flex-shrink: 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        margin-right: 16px;
        color: #949494;
      `

      const medalStyle = `
        margin-left: 4px;
        margin-right: 24px;
      `

      const name = `
        margin-right: 16px;
        color: #393939;
      `

      const line = `
        display: flex;
        align-items: center;
      `

      let numberElement = `<div style="${number}">${idx + 1}.</div>`
      const medal = (src, srcset) => `<img style="${medalStyle} "src="medal" src="${src}" srcset="${srcset}">`

      if (idx < 3) {
        numberElement = medal(medals[idx], `${medals[idx]}, ${medals2x[idx]} 2x, ${medals3x[idx]}, 3x`)
      }

      tmp.innerHTML = `
        <div style="${line}">
          ${numberElement}
          <div style="${name}">${item.name}</div>
        </div>
        <div class="points">
          <div>${item.cookies / 20}c</div>
          <div>${item.candies}ca</div>
          <div>${item.presents / 10}p</div>
          <div>${item.snowmen / 50}s</div>
          <div><strong>${item.total_points}</strong></div>
          <div style="color: #999">${item.distance}m</div>
          <div style="color: #999">${item.time ? (item.time / 60000).toFixed(2) : 0}m</div>
        </div>`
      return tmp
    }
  )

  list.mount()

  getList()
    .then(data => list.update(data))

  setInterval(() => {
    getList()
      .then(data => list.update(data))
  }, 30000)
}

const getList = () => {
  return fetch('https://master-7rqtwti-h6iieht6zrdke.eu-2.platformsh.site/api/highscores')
    .then(res => res.json())
}
