export default class {
  constructor (ctx, pos) {
    this._ctx = ctx
    this._pos = pos
    this._transforms = []
    this._element = null

    this._appendTransforms()
  }

  _appendTransforms () {
    if (this._transforms.length) {
      this._element.style.transform = this._transforms.join(', ')
    }
  }

  _generatePosition (value, axis) {
    const property = { x: 'left', y: 'top' }[axis]

    if (value !== 'center') {
      return `${property}: ${value}px;`
    } else {
      this._transforms.push(`translate${axis.toUpperCase()}(-50%)`)
      return `${property}: 50%;`
    }
  }

  mount () {
    this._ctx.appendChild(this._element)
  }

  unmount () {
    this._ctx.removeChild(this._element)
  }

  update () {

  }
}
