import controller from '../Controller'
import { clearScreens, removeAllScreensBut, initScreens, getActiveScreens, addScreens, removeScreens } from './screen'
import { dispatch, subscribe, getState } from './state'
import { preload } from './preloader'

let width = 0
let height = 0
let ctx = null

let startTime = 0
let lastTime = 0
let fingerPos = [0, 0]
let lastFingerPos = [0, 0]

const state = {}

export const init = (_ctx, _controlCtx, _width, _height) => {
  return new Promise(resolve => {
    width = _width
    height = _height
    ctx = _ctx

    preload()
      .then(() => {
        resolve()
        initScreens(_ctx, _controlCtx, _width, _height)
        initState()
        setup()
      })

    subscribe('playstate', (type) => {
      switch (type) {
        case 'playing':
          startGame()
          break
        case 'gameover':
          state.gameover()
          break
        case 'leaderboard':
          state.leaderboard()
      }
    })
  })
}

const initState = () => {
  state.menu = () => {
    addScreens('game', 'menu', 'snow')
  }
  state.test = () => {
    addScreens('test')
  }
  state.playing = () => {
    dispatch('playcount', getState('playcount') + 1)
    removeAllScreensBut('game', 'snow')
  }
  state.gameover = () => {
    if (getState('gametype') === 'special' && getState('playcount') === 1) {
      return state.wishes()
    }

    addScreens('gameover')
    initialFingerPos()
  }
  state.leaderboard = () => {
    removeScreens('gameover')
    addScreens('leaderboard')
  }
  state.intro = () => {
    clearScreens()
    addScreens('intro', 'game', 'snow')
  }
  state.wishes = () => {
    addScreens('wishes')
  }
}

const setupController = () => {
  controller(p => {
    lastFingerPos = fingerPos
    fingerPos = p
  })
}

const initialFingerPos = () => {
  fingerPos = [width / 2 - 10, height]
}

const setup = () => {
  initialFingerPos()
  startTime = performance.now()
  lastTime = startTime

  if (window.location.href.indexOf('test') !== -1) {
    state.test()
  } else if (window.location.href.indexOf('xmas') !== -1) {
    state.intro()
    dispatch('gametype', 'special')
  } else {
    state.menu()
  }

  requestAnimationFrame(update)
}

const startGame = () => {
  state.playing()
  setupController()
}

const update = (now) => {
  requestAnimationFrame(update)

  const delta = (now - lastTime)
  lastTime = now

  const direction = lastFingerPos[0] < fingerPos[0] ? 1 : -1

  getActiveScreens().forEach(s => s.update(delta, fingerPos, direction))

  draw()
}

const draw = () => {
  ctx.clearRect(0, 0, width, height)
  drawBackground()

  getActiveScreens().forEach(s => s.draw())
}

const drawBackground = () => {
  const gradient = ctx.createLinearGradient(width / 2, 0, width / 2, height)
  gradient.addColorStop(0, '#f1f1f1')
  gradient.addColorStop(1, '#eee')
  ctx.fillStyle = 'white'

  ctx.beginPath()
  ctx.fillRect(0, 0, width, height)
}
