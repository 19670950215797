import { vec2 } from 'gl-matrix'

export default class Gameobject {
  constructor (_ctx, _width, _height) {
    this._ctx = _ctx
    this._width = _width
    this._height = _height
    this._pos = vec2.set([], 0, 0)
    this.type = 'object'
    this._size = [0, 0]
    this._scale = [1, 1]

    this._animation = null

    this._animations = {
      default: (x, y) => {
        this._pos[0] = x
        this._pos[1] = y
      }
    }

    this.setAnimation()
  }

  setAnimation (type = 'default') {
    this._animation = this._animations[type]
  }

  update (time, _pos) {
    this._animation(_pos[0], _pos[1])
    return 0
  }

  getBoundingBox () {
    return [
      [
        this._pos[0] - this._size[0] / 2,
        this._pos[1]
      ],
      this._size
    ]
  }

  checkBounds () {
    if (this._pos[1] + this._size[1] < 0) {
      return -1
    }

    if (this._pos[1] > this._height) {
      return 1
    }

    return 0
  }

  drawBoundingBox () {
    const [pos, size] = this.getBoundingBox()

    this._ctx.beginPath()
    this._ctx.lineWidth = 1
    this._ctx.lineJoin = 'square'
    this._ctx.strokeStyle = '#f00'
    this._ctx.strokeRect(pos[0], pos[1], size[0], size[1])
  }

  draw () {
  }
}
