import Screen from './_Screen'
import Player from '../objects/Player'
import { boxCollision } from '../utils'
import Present from '../objects/Present'
import Turn from '../objects/Turn'

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'test'

    this._player = new Player(_ctx, _width, _height, 0, 'slopes')

    this._assets = [
      new Present(_ctx, _width, _height, [_width / 2 - 100, _height / 2])
    ]

    this._turn = new Turn(_ctx, _width, _height, [_width / 2, _height / 2])
    this._turn.ignite()
  }

  update (time, fingerPos, direction) {
    this._assets.map(a => a.update(time, fingerPos, direction))
    this._player.update(time, fingerPos, direction)
    this._assets.forEach(a => {
      if (boxCollision(this._player, a)) {
        console.log('hit ', a.type)
        a.isHit()
      }
    })
    this._turn.update([this._width / 2, this._height / 2])
  }

  draw () {
    // this._assets.forEach(a => a.drawShadow())
    this._assets.forEach(a => a.draw())
    this._player.draw()
    this._turn.draw()
  }
}
