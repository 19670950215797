import sprite from '../sounds/sprite.json'
import sound from '../sounds/sprite.mp3'
let audio = null
let currentTrack = ''

const timeupdate = () => {
  if (audio.currentTime >= currentTrack.end) {
    playSound(sprite.autoplay)
  }
}

export const initAudio = () => {
  currentTrack = sprite.autoplay
  audio = new Audio(sound)
  audio.loop = true
  audio.addEventListener('timeupdate', timeupdate)
  audio.play()
  playSound(currentTrack)
}

export const playSound = (name) => {
  if (audio && name !== currentTrack) {
    const track = sprite.spritemap[name]
    let time = track.start

    if (currentTrack === sprite.autoplay) {
      sprite.spritemap[sprite.autoplay].time = audio.currentTime
    } else if (name === sprite.autoplay) {
      time = sprite.spritemap[sprite.autoplay].time
    }

    audio.currentTime = time
    currentTrack = track
  }
}
