import _Component from './_Component'

export default class extends _Component {
  constructor (ctx, title, onClick) {
    super(ctx)
    this._element = document.createElement('button')
    this._element.className = 'link'
    this._element.innerText = title

    this._element.style = `
      font-family: Barlow, Helvetica, Arial;
      font-size: 16px;
      font-weight: 800;
      font-style: italic;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: black;
      appearance: none;
      border: none;
      padding: 0;
      background: none;
    `

    this._element.addEventListener('click', onClick)

    this._appendTransforms()
  }
}
