export default class {
  constructor (_ctx, _controlCtx, _width, _height) {
    this.name = 'default'
    this._ctx = _ctx
    this._controlCtx = _controlCtx
    this._width = _width
    this._height = _height
    this._styleEl = null
  }

  _appendStyle () {
    this._controlCtx.classList.add(this.name)
    this._styleEl = document.createElement('style')
    this._styleEl.innerHTML = this._style
    this._controlCtx.appendChild(this._styleEl)
  }

  _removeStyle () {
    if (!this._styleEl) {
      return
    }

    this._controlCtx.removeChild(this._styleEl)
    this._styleEl = null
    this._controlCtx.classList.remove(this.name)
  }

  mount () {}

  unmount () {}

  update (time, controller) {

  }

  draw () {

  }
}
