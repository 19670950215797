export default (_onMove) => {
  const state = { controlling: false }

  if ('ontouchstart' in window) {
    window.addEventListener('touchstart', (e) => (state.controlling = true))
    window.addEventListener('touchmove', (e) => {
      if (state.controlling) {
        _onMove([e.touches[0].clientX, e.touches[0].clientY])
      }
    })
    window.addEventListener('touchend', (e) => (state.controlling = false))
    return
  }

  document.addEventListener('drag', (e) => { e.preventDefault() })
  window.addEventListener('mousedown', (e) => (state.controlling = true))
  window.addEventListener('mousemove', (e) => {
    if (state.controlling) {
      _onMove([e.clientX, e.clientY])
    }
  })
  window.addEventListener('mouseup', (e) => (state.controlling = false))
}
