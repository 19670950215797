import _Component from './_Component'

export default class extends _Component {
  constructor (ctx, title, onChange, onClick) {
    super(ctx)

    this._element = document.createElement('div')
    this._element.className = 'input'

    const input = document.createElement('input')
    input.placeholder = title
    input.addEventListener('change', (e) => onChange(e.target.value))
    input.style = `
      border: none;
      appearance: none;
      border-radius: 0;
      padding: 8px 0;
      border-bottom: 1px solid #ff8911;
      outline: none;

      font-size: 16px;
      font-weight: 300;
      line-height: 1.25;
      letter-spacing: -0.5px;
      color: #838383;
      text-align: left;
    `
    this._element.appendChild(input)

    const button = document.createElement('button')
    button.className = 'button'
    button.innerText = 'Save'
    button.style = `
      appearance: none;
      color: #ff5511;
      background: white;
      padding: 0;
      border: none;
    `
    button.addEventListener('click', onClick)

    this._element.appendChild(button)
    this._appendTransforms()
  }
}
