import Screen from './_Screen'
import Button from '../components/Button'
import Text from '../components/Text'
import { Flake } from './Snow'
import { rand } from '../utils'

import { dispatch } from '../manager/state'

import Merrychristmas from '../components/Merrychristmas'
import { getAsset } from '../manager/preloader'
import Lift from '../objects/Lift'

export default class extends Screen {
  constructor (_ctx, _controlCtx, _width, _height) {
    super(_ctx, _controlCtx, _width, _height)

    this.name = 'wishes'

    this._back = getAsset('back')
    this._middle = getAsset('middle')
    this._front = getAsset('front')
    this._wagon = getAsset('wagon')
    this._lift = new Lift(_ctx, _width, _height, [0, this._height - 130])

    this._style = `
      .wishes {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 260px;
      }

      .merrychristmas {
        height: 36.85%;
        margin-bottom: 2.99%;
        max-height: 150px;
      }

      .merrychristmas svg {
        width: auto;
        height: 100%;
      }

      .text {
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.3px;;
        font-style: normal;;
        color: #ff5511;
      }

      .toptext {
        margin-top: 10.34%;
        margin-bottom: 4.49%;
      }

      .bottomtext {
        margin-bottom: 7.19%;
      }

      .cta {
        margin-top: auto;
        margin-bottom: 20px;
      }
    `

    this._snow = []

    this._text = new Text(
      _controlCtx,
      'text toptext',
      'We wish you a'
    )

    this._merryChristmas = new Merrychristmas(_controlCtx, 'merrychristmas')

    this._text2 = new Text(
      _controlCtx,
      'text bottomtext',
      'and a happy new year'
    )

    this._button = new Button(
      _controlCtx,
      'cta',
      'RIDE AGAIN',
      () => this._startGame()
    )

    this._initSnow()
  }

  mount () {
    this._appendStyle()
    this._text.mount()
    this._merryChristmas.mount()
    this._text2.mount()
    this._button.mount()
  }

  unmount () {
    this._button.unmount()
    this._text.unmount()
    this._text2.unmount()
    this._merryChristmas.unmount()
    this._removeStyle()
  }

  _startGame () {
    dispatch('playstate', 'playing')
  }

  _initSnow () {
    for (let i = 0; i < 50; i++) {
      const x = rand(this._width / 2 - 131, this._width / 2 + 131)
      const y = rand(this._height - 245, this._height)

      this._snow.push(new Flake(x, y, true, 1))
    }
  }

  update (time) {
    this._snow.forEach(s => {
      s.update()

      if (s.y >= this._height) {
        s.y = this._height - 245
      }
    })
    this._lift.update(time)
  }

  draw () {
    // draw the white screen background
    this._ctx.save()
    this._ctx.fillStyle = 'rgba(255, 255, 255, 1)'
    this._ctx.beginPath()
    this._ctx.fillRect(0, 0, this._width, this._height)
    this._ctx.restore()

    // snowball mountains + trees: clip a circle and draw background and snow into it
    this._ctx.save()
    this._ctx.beginPath()
    this._ctx.fillStyle = 'red'
    this._ctx.arc(this._width / 2, this._height - 114, 131, 0, Math.PI * 2)
    this._ctx.clip()
    this._ctx.drawImage(this._back, this._width / 2 - 131, this._height - 227 - 20, 262, 227)
    this._snow.forEach(s => s.draw(this._ctx))
    this._ctx.restore()

    // snowball house + base + wagons
    this._ctx.drawImage(this._middle, this._width / 2 - 60.5 + 34, this._height - 88 - 45, 121, 88)
    this._ctx.drawImage(this._front, this._width / 2 - 123, this._height - 92, 246, 92)

    this._lift.draw()
  }
}
