import state from '../state'

const subscribers = {}

export const dispatch = (channel, message) => {
  const prevState = state[channel]
  state[channel] = message

  if (subscribers[channel]) {
    subscribers[channel].forEach(subscriber => subscriber(state[channel], prevState))
  }
}

export const subscribe = (channel, subscription) => {
  if (!subscribers[channel]) {
    subscribers[channel] = []
  }

  subscribers[channel].push(subscription)

  if (state[channel]) {
    subscription(state[channel])
  }
}

export const getState = (channel) => state[channel]
