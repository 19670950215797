import Gameobject from './_Gameobject'
import lerp from 'lerp'

export default class extends Gameobject {
  constructor (_ctx, _width, _height, _pos = []) {
    super(_ctx, _width, _height)
    this._pos = _pos

    this._opacity = 0

    this._updateFunctions = []

    this._points = []
    for (let i = 0; i < 10; i++) {
      const angle = Math.random() * Math.PI * 2
      const distance = 2

      this._points.push(this._calcBullet(Math.random() * 10, distance, angle))
    }
  }

  _calcBullet (radius, distance, angle) {
    return {
      radius: radius,
      distance: distance,
      angle: angle,
      pos: [
        Math.sin(angle) * distance,
        Math.cos(angle) * distance
      ]
    }
  }

  ignite () {
    this._opacity = 1
    this._updateFunctions.push(() => {
      this._points = this._points.map(p => {
        return this._calcBullet(
          lerp(p.radius, 0, 0.1),
          lerp(p.distance, 20, 0.2),
          p.angle
        )
      })
    })
  }

  update (pos) {
    this._pos = pos
    this._updateFunctions.forEach(f => f())
  }

  _bullet (radius, pos) {
    this._ctx.save()
    this._ctx.translate(pos[0], pos[1])
    this._ctx.beginPath()
    this._ctx.fillStyle = '#ff5511'
    this._ctx.arc(0, 0, radius, 0, Math.PI * 2)
    this._ctx.fill()
    this._ctx.restore()
  }

  draw () {
    this._ctx.save()
    this._ctx.translate(this._pos[0], this._pos[1])
    this._ctx.globalAlpha = this._opacity
    this._points.forEach(p => {
      this._bullet(p.radius, p.pos)
    })
    this._ctx.restore()
  }
}
