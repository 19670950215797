export default {
  playstate: 'idle',
  distance: 0,
  presents: 0,
  cookies: 0,
  snowies: 0,
  candy: 0,
  presentCount: 0,
  cookieCount: 0,
  snowieCount: 0,
  candyCount: 0,
  points: 0,
  playerAnimation: '',
  gametype: 'default',
  playcount: 0,
  userId: 0,
  startTime: 0
}
